import React from 'react'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

import Layout from 'components/Layout'
import Seo from 'components/Seo'

import {
  PostTitle,
  PostContainer,
  PostImgContainer,
  PostContents,
  PostAuthor,
  PostDate,
  PostSocialsContainer,
} from 'components/BlogComponents'

const Blog = () => {
  const { t } = useTranslation('post2')
  const url = typeof window !== 'undefined' ? window.location.href : null

  return (
    <Layout>
      <Seo title={t('post2Title')} description={t('post2Paragraph1')} />
      <PostContainer>
        <PostTitle>
          <h1>{t('post2Title')}</h1>
        </PostTitle>
        <PostAuthor>
          <p>{t('post2Author')}</p>
        </PostAuthor>
        <PostDate>
          <p>{t('post2Date')}</p>
        </PostDate>
        <PostSocialsContainer>
          <FacebookShareButton
            url={url}
            quote={t('post2Paragraph1')}
            hashtag="#fluentworlds"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <TwitterShareButton url={url}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </PostSocialsContainer>

        <PostImgContainer>
          <StaticImage
            src="../graphics/common_words.jpg"
            alt="Man Practicing Common English Words"
          />
        </PostImgContainer>

        <PostContents>
          <p>
            {t('post2Paragraph1')}
            <a href="https://youtu.be/UgZjlGBKi6s">{t('post2VideoLinkText')}</a>
          </p>
          <p>{t('post2Paragraph2')}</p>
          <ul>
            <li>
              <strong>{t('post2Word1')}</strong>
              {t('post2ListItem1')}
            </li>
            <li>
              <strong>{t('post2Word2')}</strong>
              {t('post2ListItem2')}
            </li>
            <li>
              <strong>{t('post2Word3')}</strong>
              {t('post2ListItem3')}
            </li>
            <li>
              <strong>{t('post2Word4')}</strong>
              {t('post2ListItem4')}
            </li>
            <li>
              <strong>{t('post2Word5')}</strong>
              {t('post2ListItem5')}
            </li>
            <li>
              <strong>{t('post2Word6')}</strong>
              {t('post2ListItem6')}
            </li>
            <li>
              <strong>{t('post2Word7')}</strong>
              {t('post2ListItem7')}
            </li>
            <li>
              <strong>{t('post2Word8')}</strong>
              {t('post2ListItem8')}
            </li>
            <li>
              <strong>{t('post2Word9')}</strong>
              {t('post2ListItem9')}
            </li>
            <li>
              <strong>{t('post2Word10')}</strong>
              {t('post2ListItem10')}
            </li>
          </ul>
          <p>{t('post2Paragraph3')}</p>
        </PostContents>
      </PostContainer>
    </Layout>
  )
}

export default Blog
